import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { setupInterceptors } from './api'

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const store = configureStore()

setupInterceptors(store)

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div className='loading' />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export const history = createBrowserHistory()

export function configureStore(initialState) {
  const store = createStore(
    reducers(history),
    initialState,
    composeWithDevTools(compose(applyMiddleware(
      ...middlewares,
      routerMiddleware(history)
    )))
  );
  sagaMiddleware.run(sagas);
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

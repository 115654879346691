import './assets/css/vendor/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-image-lightbox/style.css';
import {
  isMultiColorActive,
  isRoundedCorner,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive
} from './constants/defaultValues';

const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

let render = () => {
  import('./assets/css/saas/themes/gogo.' + color + '.scss').then(x => {
    require('./AppRenderer');
  });
};

if (isRoundedCorner) {
  document.body.classList.add('rounded');
} else {
  document.body.classList.remove('rounded');
}

render();
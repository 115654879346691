import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';

const reducers = (history) => combineReducers({
  router: connectRouter(history),
  menu,
  settings,
  authUser
});

export default reducers;

import API from '../../api';
import { push } from 'connected-react-router';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
//import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  LOGGED_IN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UNAUTHORIZE
} from '../actions';
import {
  API_AUTH/* , API_LOGOUT */
} from '../../constants/api';
//import { USER_ROLE_DROPDOWN } from '../../constants/enums';

import {
  loginUserSuccess,
  loginUserError,
  loggedInUserSuccess,
  loggedInUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError/* ,
  unauthorize */
} from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoggedInUser() {
  yield takeEvery(LOGGED_IN_USER, loggedInUser);
}

/* const firebaseLogin = async (email, password) => {
  return await auth
    .signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .then(authUser => authUser.user.getIdToken())
    .catch(error => error);
} */

const loginWithEmailPasswordAsync = async (email, password) => {
  const body = JSON.stringify({ email, password });
  return await API.post(API_AUTH, body)
    .then(response => response)
    .catch(err => err);
}

const loginUserAsync = async () => {
  return await API.get(API_AUTH)
    .then(response => response)
    .catch(err => err);
}

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    
    /* const token = yield call(loginWithEmailPasswordAsync, email, password);
    //console.log(token)
    localStorage.setItem('token', token);
    const userResponse = yield call(loginUserAsync);
    const allowedRoles = USER_ROLE_DROPDOWN.slice(2, USER_ROLE_DROPDOWN.length + 1).map(value => value.value)
    if (allowedRoles.includes(userResponse.data.role)) {
      yield put(loginUserSuccess(JSON.stringify(userResponse.data)));
      history.push('/');
    } else {
      yield put(loginUserError("Not Allowed!"));
    } */

    const response = yield call(loginWithEmailPasswordAsync, email, password);
    if (response.status === 200) {
      const data = response.data;
      localStorage.setItem('token', data.token);
      //const userResponse = yield call(loginUserAsync);
      //const allowedRoles = USER_ROLE_DROPDOWN.slice(2, USER_ROLE_DROPDOWN.length + 1).map(value => value.value)
      //if (allowedRoles.includes(userResponse.data.role)) {
        yield put(loginUserSuccess(JSON.stringify(data)));
        history.push('/');
      //} else {
        //yield put(loginUserError("Not Allowed!"));
      //}
    } else {
      const errorResponse = response.request.response;
      const msg = JSON.parse(errorResponse);
      yield put(loginUserError(msg.message));  
    }
  } catch (error) {
    // Handle Errors here.
    //var errorCode = error.code;
    //var errorMessage = error.message;
    console.log(error)
    yield put(loginUserError("Something went wrong!"));
    /* if (errorCode === 'auth/wrong-password') {
      yield put(loginUserError(errorMessage));
    } else {
      yield put(loginUserError("Something went wrong!"));
    } */
  }
}

function* loggedInUser({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(loginUserAsync);
    if (response.status === 200) {
      const data = response.data;
      yield put(loggedInUserSuccess(JSON.stringify(data)));
      history.push('/');
    } else {
      const errorResponse = response.request.response;
      const msg = JSON.parse(errorResponse);
      yield put(loggedInUserError(msg.message));  
    }
  } catch (error) {
    yield put(loggedInUserError("Something went wrong!"));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

/* const registerWithEmailPasswordAsync = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error); */

function* registerWithEmailPassword({ payload }) {
  yield put(registerUserError("Error"));
  yield put(registerUserSuccess("test"));
  /* const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      yield put(registerUserSuccess(registerUser));
      history.push('/');
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  } */
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async history => {
  return Promise.resolve('Success')
    .then(() => {
      history.push('/');
    })
    .catch(error => error);
  /* return await auth
    .signOut()
    .then(() => {
      history.push('/');
    })
    .catch(error => error); */
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
  } catch (error) {}
}

export function* watchUnauthorize() {
  yield takeEvery(UNAUTHORIZE, unauthorizeUser);
}

function* unauthorizeUser() {
  try {
    yield put(push('/user/login'))
    //yield put(unauthorize());
  } catch (error) {}
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

/* const forgotPasswordAsync = async email => {
  return await auth
    .sendPasswordResetEmail(email)
    .then(user => user)
    .catch(error => error);
}; */

function* forgotPassword({ payload }) {
  yield put(forgotPasswordSuccess('success'));
  yield put(forgotPasswordError('error'));
  /* const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  } */
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

/* const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then(user => user)
    .catch(error => error);
}; */

function* resetPassword({ payload }) {
  yield put(resetPasswordSuccess('success'));
  yield put(resetPasswordError('error'));
  /* const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  } */
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchUnauthorize)
  ]);
}
